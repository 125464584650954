@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --blue-100: #2C95E8;
    --blue-5: #F1F8FD;
    --blue-20: #E4F1FC;
    --blue-30: #CDE6F9;
    --blue-40: #C3E1F8;
    --blue-60: #95C9F3;
    --blue-120: #1678C5;
    --blue-170: #08528C;

    --red-100: #E97E63;
    --red-120: #e54421;
    --red-30: #FDD1C9;
    --red-170-40: rgba(159, 58, 30, 0.4);
    --red-170: #9F3A1E;

    --yellow-100: #F3AB1B;
    --yellow-120: #e79109;
    --yellow-30: #FBE4B6;
    --yellow-170-40: rgba(153, 102, 0, 0.4);
    --yellow-170: #996600;

    --green-100: #2FB163;
    --green-120: #168c4c;
    --green-30: #C4EDCE;
    --green-170-40: rgba(40, 113, 69, 0.4);
    --green-170: #287145;

    --purple-100: #7147C2;
    --purple-30: #E4D6FF;
    --purple-60: #AC94DB;
    --purple-120: #5935A0;

    --orange-100: #F36E1B;
    --orange-30: #FFE0CC;
    --orange-60: #F9B58B;
    --orange-120: #DA5B0B;

    --black-100: #212121;
    --white-100: #FFFFFF;
    --white-100-20: rgba(255, 255, 255, 0.2);
    --white-100-50: rgba(255, 255, 255, 0.5);
    --black-5: #F5F5F5;
    --black-10: #e5e5e5;
    --black-15: #DEDEDE;
    --black-30: #BFBFBF;
    --black-50: #A1A1A1;
    --black-60: #8A8A8A;
    --black-70: #666666;

    --menu-size: 260px;
}

::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
}

body * {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
}

textarea {
    font-size: .9em;
    font-weight: 500;
}

.input {
    height: 36px;
    border-radius: 12px;
    border: 1px solid var(--black-15);
    padding: 0 15px;
    position: relative;
    width: 100%;
    color: var(--blue-120);
    font-weight: 500;
    font-size: .9em;
}

.input::placeholder {
    color: var(--black-70);
}

.input.number {
    width: 90px;
}

.modal-bottom-button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.application-mode-info {
    position: absolute;
    top: 6px;
    left: 6px;
    background: var(--orange-30);
    font-size: 0.8em;
    font-weight: 700;
    border: 1px solid var(--orange-60);
    padding: 0 6px 0 20px;
    border-radius: 6px;
    color: var(--orange-120);
}

.application-mode-info:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 6px;
    width: 8px;
    height: 8px;
    background: var(--orange-100);
    border-radius: 4px;
    transform: translate(0, -50%);
}

.euro-input input {
    width: 150px;
    padding-right: 50px;
}

.euro-input {
    position: relative;
}

.euro-input:after {
    content:'';
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--black-5);
    border-radius: 0 12px 12px 0;
    border: 1px solid var(--black-15);
}

.euro-input:before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translate(0, -50%);
    background: url('../icons/euro--black.svg') no-repeat;
    background-size: contain;
    z-index: 9;
}

input:focus-visible, textarea:focus-visible {
    outline: transparent;
}

.main-router-content, .portal-content {
    min-height: calc(100% - 80px);
    height: 100%;
}

.input-container:before, .input-container-search:before, .input-address-container:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    width: 14px;
    height: 14px;
    background: url('../icons/search--gray.svg') no-repeat;
    background-size: contain;
    z-index: 1;
}

.block-header-note {
    align-items: center;
    background: #f1f8fd;
    background: var(--blue-5);
    border-radius: 12px;
    color: var(--blue-100);
    display: flex;
    font-size: .9em;
    justify-content: center;
    padding: 8px 15px;
    width: fit-content;
    margin-bottom: 30px;
}

.list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.without-top {
    padding-top: 0;
}

.header-buttons {
    white-space: nowrap;
}

.header-buttons button {
    margin-left: 10px;
}

.header-input {
    width: 100%;
}

.header-input input {
    font-size: 1.6em;
    font-weight: 700;
    border: 0;
    width: 100%;
    padding: 0;
    height: 40px;
}

.header-input input::placeholder {
    color: var(--black-15);
}

.header-title h1, .view-title h1 {
    font-size: 1.4em;
    font-weight: 700;
    padding-left: 36px;
    position: relative;
    margin-bottom: 0;
}

.header-title h1:before, .view-title h1:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 22px;
    height: 22px;
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.header-title.settings h1:before {
    background: url('../icons/settings--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.pools h1:before {
    background: url('../icons/water-ladder--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.user h1:before {
    background: url('../icons/round-user--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.subscriptions h1:before {
    background: url('../icons/shopping-bag--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.calendar h1:before {
    background: url('../icons/calendar-day--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.settings h1:before {
    background: url('../icons/settings--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.edit h1:before {
    background: url('../icons/pencil--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.categories h1:before {
    background: url('../icons/apps--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.customer h1:before {
    background: url('../icons/round-user--blue.svg') no-repeat;
    background-size: contain;
}

.view-header.users h1:before {
    background: url('../icons/admins--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.users h1:before {
    background: url('../icons/admins--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.categories h1:before {
    background: url('../icons/apps--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.subscriptions h1:before {
    background: url('../icons/ticket-blue.svg') no-repeat;
    background-size: contain;
}

.header-title.pools h1:before {
    background: url('../icons/water-ladder--blue.svg') no-repeat;
    background-size: contain;
}

.header-title.trainings h1:before {
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
}

.header-title h2 {
    font-size: 1.6em;
    font-weight: 800;
    padding-left: 50px;
    position: relative;
    margin-bottom: 0;
}

.header-title h2:before {
    content: '';
    position: absolute;
    left: 2px;
    top: 5px;
    width: 24px;
    height: 24px;
    background: url('../icons/folder-blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.header-title.users h2:before {
    left: 3px;
    top: 3px;
    width: 24px;
    height: 24px;
    background: url('../icons/account.svg') no-repeat;
    background-size: contain;
}

.main-button, .small-button {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    height: 34px;
    padding: 0 12px 0 34px;
    font-weight: 600 !important;
    border-radius: 12px;
    position: relative;
    margin-bottom: 0;
    transition: all .3s;
    cursor: pointer;
    font-size: 0.9em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-100);
}

.empty-list-container .main-button {
    font-size: 1em;
}

.main-button.back-button, .main-button.options-button {
    padding: 0;
    width: 34px;
}

.main-button.back-button:before {
    background: url('../icons/arrow-left--gray.svg') no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main-button.options-button:before {
    background: url('../icons/settings--gray.svg') no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main-button.options-button:hover:before {
    background: url('../icons/settings--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.back-button:hover:before {
    background: url('../icons/arrow-left--blue.svg') no-repeat;
    background-size: contain;
}

.small-squared-button {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    height: 26px;
    width: 26px;
    border-radius: 10px;
    position: relative;
    transition: all .3s;
}

.squared-button {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    height: 34px;
    width: 34px;
    font-weight: 500;
    border-radius: 12px;
    position: relative;
    transition: all .3s;
}

.squared-button:hover {
    background: var(--blue-5);
}

.input-container .squared-button {
    margin-left: 10px;
}

.squared-button.blue-button {
    background: var(--blue-100);
}

.squared-button.blue-button:hover {
    background: var(--blue-120);
}

.squared-button.blue-button:before {
    width: 17px;
    top: 9px;
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
}

.squared-button:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 4px;
    background: url('../icons/options--gray.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.main-button.warning-button {
    border: 1px solid var(--red-30);
    color: var(--red-100);
}

.main-button.warning-button:hover {
    border: 1px solid var(--red-100);
    background: var(--red-100);
    color: var(--white-100);
}

.main-button.without-icon {
    padding: 0 20px;
}

.main-button.without-icon:before {
    display: none;
}

.input-group {
    padding: 30px;
}

.input-group.without-top {
    padding: 0 20px 20px 20px;
}

.input-label {
    width: 200px;
    padding-left: 28px;
    position: relative;
}

.input-container, .input-container-search {
    width: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.input-container input, .input-container-search input {
    width: 100%;
}

.input-container.without-icon:before {
    display: none;
}

.input-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.input-label {
    font-weight: 600;
    font-size: .9em;
}

.input-row-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.entity-add-container .input-row-container:last-child {
    margin-bottom: 0;
}

.input-row-container.textarea {
    align-items: flex-start;
}

.input-row-container.textarea .input-textarea {
    width: calc(100% - 200px);
}

.input-row-container.align-columns {
    flex-direction: column;
    align-items: flex-start;
}

.input-row-container.align-columns .input-container {
    width: 100%;
}

.input-row-container.align-columns .input-label {
    margin-bottom: 15px;
}

.input-textarea-container {
    width: 100%;
    margin-bottom: 40px;
}

.input-textarea-container:last-child {
    margin-bottom: 0;
}

.input-textarea textarea, .textarea-view {
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--black-15);
    padding: 10px 15px;
    position: relative;
    resize: none;
}

.textarea-view {
    min-height: 120px;
    white-space: pre-line;
    width: calc(100% - 200px);
}

.main-button:hover, .small-button:hover {
    border: 1px solid var(--blue-60);
    background: var(--blue-20);
    color: var(--blue-100);
}

.main-button.blue-button {
    background: var(--blue-100);
    border: 0;
    color: var(--white-100);
    font-weight: 600 !important;
    position: relative;
}

.main-button.red-button {
    background: var(--red-100);
    border: 0;
    color: var(--white-100);
    font-weight: 600 !important;
    position: relative;
}

.main-button.red-button:hover {
    background: var(--red-120);
}

.main-button.red-button:before, .main-button.red-button:hover:before {
    background: url('../icons/ban--white.svg') no-repeat;
    background-size: contain;
}

.main-button.yellow-button {
    background: var(--yellow-100);
    border: 0;
    color: var(--white-100);
    font-weight: 600 !important;
    position: relative;
}

.main-button.yellow-button:hover {
    background: var(--yellow-120);
}

.main-button.yellow-button:before, .main-button.yellow-button:hover:before {
    background: url('../icons/refresh--white.svg') no-repeat;
    background-size: contain;
}

.main-button.green-button {
    background: var(--green-100);
    border: 0;
    color: var(--white-100);
    font-weight: 600 !important;
    position: relative;
}

.main-button.green-button:hover {
    background: var(--green-120);
}

.main-button.green-button:before, .main-button.green-button:hover:before {
    background: url('../icons/check-circle--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button:disabled, .main-button.blue-button:disabled:hover {
    background: var(--blue-20);
}

.main-button.blue-button:hover {
    background: var(--blue-120);
}

.main-button.blue-button:before {
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.main-button.blue-button.launch:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.payment:before {
    background: url('../icons/payment--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.payment:hover:before {
    background: url('../icons/payment--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.launch:hover:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.edit:before {
    background: url('../icons/pencil--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.edit:hover:before {
    background: url('../icons/pencil--white.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.validate:before {
    background: url('../icons/check--white.svg') center no-repeat;
    background-size: contain;
}

.main-button.blue-button.validate:hover:before {
    background: url('../icons/check--white.svg') center no-repeat;
    background-size: contain;
}

.main-button.blue-button:hover:before {
    background: url('../icons/plus-white.svg') no-repeat;
    background-size: contain;
}

.main-button:hover:before, .small-button:hover:before {
    background: url('../icons/filter-blue.svg') no-repeat;
    background-size: contain;
}

.main-button:before, .small-button:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    width: 14px;
    height: 14px;
    background: url('../icons/filter-blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.main-button.add:before {
    background: url('../icons/round-add--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.blue-button.add:before {
    background: url('../icons/round-add--white.svg') no-repeat;
    background-size: contain;
}

.main-button.download:before {
    background: url('../icons/download-full--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.view:before {
    background: url('../icons/eye--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.login:before {
    background: url('../icons/sign-in--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.validate:before {
    background: url('../icons/check--black.svg') no-repeat;
    background-size: contain;
}

.main-button.validate:hover:before {
    background: url('../icons/check--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
}

.main-button.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.main-button.archive:before {
    background: url('../icons/archive--black.svg') no-repeat;
    background-size: contain;
}

.main-button.archive:hover:before {
    background: url('../icons/archive--blue.svg') no-repeat;
    background-size: contain;
}

.main-button.small-button.download:before {
    top: 9px;
    left: 11px;
}

.search-list {
    position: absolute;
    background: var(--white-100);
    width: 100%;
    top: 40px;
    border-radius: 0 0 12px 12px;
    border: 1px solid var(--black-15);
    border-top: 0;
    max-height: 306px;
    overflow: hidden;
}

.search-list.hidden {
    display: none;
}

.input-container-search input:focus {
    border-radius: 12px 12px 0 0;
}

.search-list span {
    font-size: 0.8em;
    padding: 10px;
    display: block;
    font-weight: 500;
    color: var(--black-70);
}

.custom-tag, .single-search-input .select__single-value, .multi-search-input .select__multi-value {
    font-weight: 600 !important;
    color: var(--blue-120);
    background: var(--blue-20);
    padding: 2px 9px;
    width: fit-content;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: .9em;
}

.view-container.subscriptions .entity-add-container .custom-tag {
    padding: 0 9px;
}

.multi-search-input .select__multi-value {
    margin-right: 5px;
    padding: 2px 2px 2px 9px;
}

.multi-search-input .select__multi-value__remove {
    padding: 2px;
    border-radius: 10px;
    margin: 0 2px;
    cursor: pointer;
}

.multi-search-input .select__multi-value__label {
    color: var(--blue-120);
    padding: 0;
    font-size: 1em;
    font-weight: 600;
}

.custom-tag-container {
    width: 100%;
    padding: 8px;
    cursor: pointer;
    border-radius: 12px;
}

.custom-tag-container.active .custom-tag {
    background: var(--green-30);
    color: var(--green-170);
}

.custom-tag-container.inactive .custom-tag {
    background: var(--black-10);
    color: var(--black-60);
}

.custom-tag-container:hover {
    background: var(--black-5);
}

.searchbar-option {
    padding: 4px 8px;
}

.select__menu-list .searchbar-option:first-child {
    padding-top: 8px;
}

.select__menu-list .searchbar-option:last-child {
    padding-bottom: 8px;
}

.searchbar-option-content {
    border: 1px solid var(--black-5);
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    font-size: .9em;
}

.searchbar-option-content:hover {
    background: var(--blue-5);
    border: 1px solid var(--blue-20)
}

.searchbar-option-content:hover .searchbar-option-subscriptions {
    border-top: 1px solid var(--blue-20)
}

.searchbar-option .searchbar-searchtype {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 8px;
}

.searchbar-option .searchbar-searchtype span, .searchbar-option .searchbar-option-subscription .custom-tag {
    font-size: .9em;
    font-weight: 600;
    padding: 2px 6px;
    border-radius: 6px;
}

.searchbar-option-subscription-title {
    display: flex;
    align-items: center;
    gap: 8px;
}

.searchbar-option-subscription-title .MuiButtonBase-root {
    padding: 3px;
}

.searchbar-option-subscription-title .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
}

.searchbar-option .searchbar-searchtype span.customer {
    color: var(--purple-120);
    background: var(--purple-30);
}

.searchbar-option .searchbar-searchtype span.profile {
    color: var(--green-120);
    background: var(--green-30);
}

.searchbar-option .searchbar-searchtype span.federal-id {
    color: var(--blue-120);
    background: var(--blue-20);
}

.searchbar-option-subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchbar-option-content .searchbar-data {
    padding-left: 24px;
    position: relative;
    color: #545454;
}

.searchbar-option-content .searchbar-data:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 16px;
    height: 16px;
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
}

.searchbar-option-content .searchbar-data.name {
    font-weight: 700;
    color: var(--black-100);
}

.searchbar-option-content .searchbar-data.name:before, .searchbar-option-content .searchbar-data.parent:before {
    background: url('../icons/round-user--blue.svg') no-repeat;
    background-size: contain;
}

.searchbar-option-content .searchbar-data.email:before {
    background: url('../icons/email-blue.svg') no-repeat;
    background-size: contain;
}

.searchbar-option-subscriptions {
    padding-top: 6px;
    margin-top: 6px;
    border-top: 1px solid var(--black-5);
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.selected-tag {
    position: absolute;
    z-index: 10;
}

.hidden {
    display: none;
}

.single-search-input, .multi-search-input {
    width: 100%;
    border-radius: 12px;
}

.single-search-input .select__value-container, .multi-search-input .select__value-container {
    padding: 0 14px;
}

.single-search-input .select__value-container.select__value-container--has-value, .multi-search-input .select__value-container.select__value-container--has-value {
    padding: 5px;
    display: flex;
    gap: 5px 0;
}

.select__indicators .select__indicator {
    padding: 0 8px;
}

.input-row-container .input-container .single-search-input .select__value-container, .input-row-container .input-container .multi-search-input .select__value-container {
    padding-left: 36px;
}

.single-search-input .select__control, .multi-search-input .select__control {
    border-radius: 12px;
    border-color: var(--black-15);
    min-height: 36px;
}

.select__control .select__placeholder {
    font-size: .9em;
    font-weight: 500;
    color: var(--black-70);
}

.select__control .select__input-container {
    margin: 0;
}

.single-search-input .select__menu, .multi-search-input .select__menu {
    z-index:11;
    border-radius: 12px;
}

.full-width {
    width: 100%;
}

.single-search-input .select__menu-list::-webkit-scrollbar, .multi-search-input .select__menu-list::-webkit-scrollbar {
    display: none;
}

.single-search-input .select__menu-list, .multi-search-input .select__menu-list {
    padding: 0;
    -ms-overflow-style: none;
}

.list-container table {
    width: 100%;
    font-size: .9em;
}

.list-container thead {
    background: var(--blue-5);
    border-bottom: 1px solid var(--blue-20);
    color: var(--black-70);
}

.list-container tbody tr {
    border-bottom: 1px solid var(--black-5);
}

.list-container tbody tr:last-child {
    border-bottom: 0;
}

.loader-container {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-container {
    border-top: 1px solid var(--black-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: .8em;
    color: var(--black-70);
    font-weight: 500;
}

.checkbox-container {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: flex-start;
}

.checkbox-container.categories {
    gap: 10px;
    border: 1px solid var(--black-5);
    padding: 8px;
    border-radius: 12px;
}

.checkbox-container.categories span {
    font-weight: 600;
}

.checkbox-container.pastille-checkbox-div {
    padding-left: 45px;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    height: 20px;
    width: 20px;
    border-radius: 7px;
    background: var(--black-5);
    border: 1px solid var(--black-15);
    transition: all .2s;
    position: relative;
}

.checkbox-container:hover input ~ .checkmark {
    background-color: #efefef;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: var(--blue-100);
    border: 1px solid var(--blue-100);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background: url('../icons/white-smooth-check.svg') no-repeat;
    background-size: cover;
    transition: all .2s;
}

.pagination-button {
    border: 1px solid var(--black-15);
    background: var(--white-100);
    border-radius: 10px;
    width: 30px;
    height: 30px;
    margin: 0 2px;
    font-weight: 600;
    color: var(--black-70);
    padding: 0;
    transition: all .2s;
}

.pagination-button.select {
    width: fit-content;
}

.pagination-button:hover {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
    color: var(--white-100);
}

.pagination-button:disabled {
    border: 1px solid var(--black-15);
    color: var(--black-15);
}

.pagination-button:disabled:hover {
    background: var(--white-100);
    border: 1px solid var(--black-15);
    color: var(--black-15);
}

.padding-right {
    padding-right: 10px;
}

.list-container th {
    position: relative;
    font-weight: 700 !important;
    font-size: .9em !important;
    color: var(--black-70);
}

.list-container td, .list-container td a, .list-container td span {
}

.sorted, .sorted-desc {
    position: relative;
}

.sorted:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translate(0, -50%);
    width: 12px;
    height: 12px;
    background: url('../icons/caret-up--gray.svg') no-repeat;
    background-size: contain;
}

.sorted-desc:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translate(0, -50%) rotate(180deg);
    width: 12px;
    height: 12px;
    background: url('../icons/caret-up--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon {
    padding-left: 24px;
    position: relative;
}

.list-container .column-icon:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 15px;
    height: 15px;
    background: url('../icons/info--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.user:before {
    background: url('../icons/round-user--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.group:before {
    background: url('../icons/swimmer--gray.svg') no-repeat;
    background-size: contain;
}

.list-container .column-icon.date:before {
    background: url('../icons/calendar-day--gray.svg') no-repeat;
    background-size: contain;
}

.custom-tag.active, .custom-tag.delivered, .custom-tag.true, .custom-tag.accepted {
    background: var(--green-30);
    color: var(--green-170);
}

.custom-tag.progress, .custom-tag.waiting-validation {
    background: var(--yellow-30);
    color: var(--yellow-170);
}

.custom-tag.production, .custom-tag.change-needed, .customer-message.change-needed {
    background: var(--orange-30);
    color: var(--orange-120);
}

.custom-tag.inactive, .custom-tag.difficulty, .custom-tag.false, .custom-tag.refused {
    background: var(--red-30);
    color: var(--red-170);
}

.custom-tag.archived, .custom-tag.draft {
    background: var(--black-5);
    color: var(--black-70);
}

.custom-tag.fabrics, .custom-tag.waiting-payment {
    background: var(--purple-30);
    color: var(--purple-120);
}

.list-container a {
    text-decoration: none;
    color: var(--black-100);
    font-weight: 500;
}

.view-container {
    padding: 15px;
}

.view-container.without-top {
    padding-top: 0;
}

.view-container.scrollable {
    height: calc(100vh - 120px);
    overflow-y: scroll;
}

.settings-view .view-container.scrollable {
    height: calc(100vh - 200px);
}

.view-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    border-bottom: 1px solid var(--black-5);
    min-height: 60px;
}

.view-fixed-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-bottom: 1px solid var(--black-5);
    height: 60px;
}

.view-header.flex-start {
    align-items: flex-start;
}

.view-title h1, .view-fixed-title h1, .custom-modal-header h1 {
    font-size: 1.4em;
    font-weight: 700 !important;
    position: relative;
    margin-bottom: 0;
}

.view-subtitle.with-description {
    margin-bottom: 10px;
}

.view-subtitle {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 30px;
}

.view-subtitle.flex-start {
    justify-content: flex-start;
}

.view-subtitle h2 {
    font-size: .9em;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    padding-left: 26px;
    line-height: 1;
}

.view-subtitle:before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background: url('../icons/info--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.categories:before {
    background: url('../icons/apps--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.pools:before {
    background: url('../icons/water-ladder--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.discount:before {
    background: url('../icons/discount--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.user:before, .subscription-line.user:before {
    background: url('../icons/round-user--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-line.field:before {
    background: url('../icons/text--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.calendar:before, .subscription-line.calendar:before {
    background: url('../icons/calendar-day--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.file:before {
    background: url('../icons/folder--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.notes:before, .subscription-line.notes:before {
    background: url('../icons/note--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.field:before {
    background: url('../icons/text--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.ticket:before {
    background: url('../icons/ticket--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.price:before {
    background: url('../icons/payment--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.options:before {
    background: url('../icons/round-add--blue.svg') no-repeat;
    background-size: contain;
}

.view-subtitle.group:before {
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-line.group:before {
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-line.status:before {
    background: url('../icons/status--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-line.id:before {
    background: url('../icons/id--blue.svg') no-repeat;
    background-size: contain;
}

.view-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.margin-left {
    margin-left: 10px;
}

.margin-right {
    margin-right: 10px;
}

.margin-top {
    margin-top: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.view-title .view-id, .portal-title span {
    color: var(--black-15);
    font-weight: 600;
    display: block;
}

.classic-input, .search-input, .contact-container {
    height: 34px;
    border-radius: 12px;
    border: 1px solid var(--black-15);
    padding: 0 15px;
}

.classic-input.hour-input {
    width: 80px;
    text-align: center;
    font-size: .9em;
    font-weight: 600;
    color: var(--blue-120);
}

.search-input {
    padding-left: 40px;
}

.input-required, .input-required:focus-visible {
    border: 2px solid var(--red-100);
    outline: transparent;
}

.relative {
    position: relative;
}

.input-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-date-container .react-datepicker {
    border-radius: 12px;
    border: 1px solid var(--black-15);
}

.input-date-container .react-datepicker__header {
    border-top-left-radius: 12px;
    border-bottom: 1px solid var(--black-15);
}

.input-date-container .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 12px;
}

.input-date-container .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: var(--black-15);
}

.input-date-container .react-datepicker__year-read-view--down-arrow, .input-date-container .react-datepicker__month-read-view--down-arrow, .input-date-container .react-datepicker__month-year-read-view--down-arrow, .input-date-container .react-datepicker__navigation-icon::before {
    top: 12px;
}

input.name-input-required::placeholder {
    color: var(--red-30);
}

.input-date-container .date-view, .input-classic-container {
    height: 40px;
    border: 1px solid var(--black-15);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 12px;
    font-weight: 500;
}

.input-classic-container {
    width: 100%;
    height: 50px;
    border-radius: 16px;
    padding: 0 20px;
    justify-content: left;
}

th.checkbox-fixed-size {
    width: 60px;
}

.main-button.small-button {
    height: 35px;
    padding: 0 12px 0 35px;
}

label.main-button.small-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-button.small-button.blue-button:before {
    left: 10px;
    top: 9px;
}

.delete-icon-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 0;
    background: var(--white-100);
    transition: all .3s;
}

.delete-icon-button:hover {
    background: var(--red-30);
}

.delete-icon-button:before {
    content:'';
    position: absolute;
    left: 7px;
    top: 6px;
    width: 17px;
    height: 17px;
    background: url('../icons/delete-full--red.svg') no-repeat;
    background-size: contain;
}

.contact-container .delete-icon-button {
    top: 10px;
}

.update-icon-button {
    position: absolute;
    top: 11px;
    right: 40px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 0;
    background: var(--white-100);
    transition: all .3s;
}

.update-icon-button:before {
    content:'';
    position: absolute;
    left: 7px;
    top: 6px;
    width: 17px;
    height: 17px;
    background: url('../icons/pen-blue.svg') no-repeat;
    background-size: contain;
}

.custom-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.2);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal.hidden {
    display: none;
}

.custom-modal-container {
    width: 600px;
    background: var(--white-100);
    border-radius: 8px;
    padding: 15px;
}

.MuiFormControl-root.MuiTextField-root.small-width {
    max-width: 100px;
}

.MuiFormControl-root.MuiTextField-root.hour-picker {
    min-width: 120px;
}

.custom-modal-content {
    margin-top: 10px;
    max-height: 80vh;
    overflow-y: scroll;
    padding: 10px 2px 0 2px;
}

.custom-modal-header {
    border-bottom: 1px solid var(--black-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.subscription-validation .custom-file-container {
    margin-bottom: 0;
}

.empty-info {
    display: block;
    font-size: .9em;
    border: 1px solid var(--black-5);
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    color: var(--black-70);
}

.custom-modal-header .modal-close, .selected-club-container .modal-close {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6em;
    transform: rotate(45deg);
    border: 0;
    background: transparent;
    transition: all .3s;
}

.custom-modal-header .modal-close:hover, .selected-club-container .modal-close:hover {
    background: var(--black-5);
}

.submit-right-container {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.contact-container {
    width: 100%;
    height: 50px;
    display: flex;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    position: relative;
    padding: 0 75px 0 20px;
    font-weight: 500;
}

.update-icon-button:hover {
    background: var(--blue-5);
}

.contact-container.full-width {
    padding: 0 20px;
}

.contact-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-info span {
    width: 100%;
}

.input-address-container {
    font-weight: 500;
    width: 100%;
    border: 1px solid var(--black-15);
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    border-radius: 16px;
    position: relative;
}

.input-address-container:before {
    top: 15px;
}

.no-value {
    font-weight: 500;
    width: 100%;
    background: var(--red-30);
    border: 1px solid var(--red-100);
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 16px;
    position: relative;
}

.provider-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.provider-block {
    width: 210px;
    height: 120px;
    margin-right: 18px;
    border: 1px solid var(--black-15);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all .3s;
}

.provider-block.fabrics:hover, .provider-block.fabrics.checked {
    background: var(--purple-30);
    border: 1px solid var(--purple-60);
}

.provider-block.fabrics:hover .provider-check, .provider-block.fabrics.checked .provider-check {
    border: 1px solid var(--purple-60);
}

.provider-block .provider-check:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 10px;
    transition: all .3s;
}

.provider-block.fabrics.checked .provider-check:before {
    background: var(--purple-100);
}

.provider-block.production.checked .provider-check:before {
    background: var(--orange-100);
}

.provider-block.logistics.checked .provider-check:before {
    background: var(--blue-100);
}

.provider-block.production:hover, .provider-block.production.checked {
    background: var(--orange-30);
    border: 1px solid var(--orange-60);
}

.provider-block.production:hover .provider-check, .provider-block.production.checked .provider-check {
    border: 1px solid var(--orange-60);
}

.provider-block.logistics:hover, .provider-block.logistics.checked {
    background: var(--blue-30);
    border: 1px solid var(--blue-60);
}

.provider-block.logistics:hover .provider-check, .provider-block.logistics.checked .provider-check {
    border: 1px solid var(--blue-60);
}

.provider-check {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    border: 1px solid var(--black-15);
    top: 12px;
    right: 12px;
    background: var(--black-5);
    transition: all .3s;
}

.provider-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
}

.production .provider-content img {
    height: 30px;
}

.provider-content img {
    height: 26px;
    margin-bottom: 10px;
}

#file-add {
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-block {
    height: 190px;
    width: 200px;
    margin-right: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    display: inline-block;
    margin-bottom: 20px;
}

.file-preview {
    height: 110px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}

.file-info {
    height: 80px;
    border-radius: 0 0 12px 12px;
    position: relative;
}

.file-info span {
    width: calc(100% - 10px);
    display: block;
    padding: 5px 10px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
}

.file-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.file-buttons a {
    height: 30px;
    display: flex;
}

.file-button {
    width: 40px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid var(--blue-30);
    background: var(--white-100);
    margin-left: 5px;
    position: relative;
    transition: all .3s;
}

.file-button:hover {
    border: 1px solid var(--blue-100);
    background: var(--blue-100);
}

.file-button.view:hover:before {
    background: url('../icons/see-more--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:hover:before {
    background: url('../icons/delete-full--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:hover {
    border: 1px solid var(--red-100);
    background: var(--red-100);
}

.file-button.delete {
    border: 1px solid var(--red-30);
}

.file-button.view:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 9px;
    width: 20px;
    height: 20px;
    background: url('../icons/see-more--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.file-button.download:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 12px;
    width: 16px;
    height: 16px;
    background: url('../icons/download-full--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.file-button.download:hover:before {
    background: url('../icons/download-full--white.svg') no-repeat;
    background-size: contain;
}

.file-button.delete:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 11px;
    width: 16px;
    height: 16px;
    background: url('../icons/delete-full--red.svg') no-repeat;
    background-size: contain;
}

.files-container {
    margin-bottom: 20px;
    width: 100%;
}

.custom-page-file canvas {
    width: 200px !important;
    height: auto !important;
    min-height: 110px !important;
}

.file-preview img {
    width: 200px;
    height: auto;
    min-height: 110px;
}

.scrollable-page {
    overflow-y: scroll;
    height: calc(100% - 80px);
}

.scrollable-actions {
    overflow-y: scroll;
    height: calc(100% - 115px);
}

.frequency-container {
    font-weight: 500;
}

.frequency-container input, .frequency-container select {
    margin-left: 10px;
    height: 35px;
    border-radius: 10px;
    border: 1px solid var(--black-15);
    padding: 0 10px;
}

.flex-view {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.flex-view .left-container {
    width: 60%;
}

.flex-view .right-container {
    width: 40%;
    height: calc(100% - 80px);
    border-left: 1px solid var(--black-5);
    position: relative;
}

.order-portal .flex-view .right-container {
    height: calc(100% - 100px);
}

.action-date {
    font-size: .8em;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--black-50);
    text-align: center;
    padding: 6px 0;
}

.action-block {
    padding: 6px 0;
}

.custom-action:first-child {
    padding-top: 6px;
}

.custom-action:last-child {
    padding-bottom: 6px;
}

.action-block.message, .order-portal .action-block.provider-message {
    display: flex;
    padding-right: 25px;
    justify-content: flex-end;
}

.action-block.provider-message, .order-portal .action-block.message {
    display: flex;
    padding-left: 25px;
    justify-content: flex-start;
}

.action-block.provider-message span, .order-portal .action-block.message span {
    white-space: pre-line;
    display: block;
    width: 75%;
    background: var(--black-5);
    padding: 15px;
    border-radius: 16px;
    font-weight: 500;
    font-size: .92em;
    color: var(--black-100);
}

.action-block.message span, .order-portal .action-block.provider-message span {
    white-space: pre-line;
    display: block;
    width: 75%;
    background: var(--blue-100);
    color: white;
    padding: 15px;
    border-radius: 16px;
    font-weight: 500;
    font-size: .92em;
}

.action-block.date-event {
    display: flex;
    justify-content: center;
    font-size: .9em;
    font-weight: 600;
    color: var(--black-60);
    padding: 8px 16px;
    text-align: center;
}

.action-block span {
    position: relative;
}

.action-block.date-event span {
    padding-left: 22px;
}

.action-block.date-event span:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 15px;
    height: 15px;
    background: url('../icons/calendar--gray.svg') no-repeat;
    background-size: contain;
}

.action-sender-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    height: 115px;
    width: 100%;
    border-top: 1px solid  var(--black-5);
}

.action-sender-input {
    width: 85%;
    padding: 20px 0 20px 20px;
    height: 100%;
}

.action-sender-input textarea {
    background: var(--black-5);
    border: 0;
    border-radius: 16px;
    width: 100%;
    padding: 10px 15px;
    height: 100%;
    resize: none;
}

.action-sender-button {
    width: 15%;
    padding: 30px 15px 30px 15px;
    height: 100%;
}

.sender-button {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 16px;
    position: relative;
    background: var(--white-100);
    transition: all .3s;
}

.sender-button:before {
    content: '';
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 22px;
    height: 22px;
    background: url('../icons/send--blue.svg') no-repeat;
    background-size: contain;
}

.sender-button:hover {
    background: var(--blue-5);
}

.order-portal {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.portal-header {
    height: 100px;
    border-bottom: 1px solid var(--black-5);
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portal-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.portal-title img {
    width: 50px;
    margin-right: 20px;
}

.portal-phone {
    border: 1px solid var(--black-15);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px 0 45px;
    border-radius: 12px;
    color: var(--black-100);
    transition: all .3s;
    position: relative;
}

.portal-phone:before {
    content: '';
    position: absolute;
    left: 15px;
    top: 9px;
    width: 20px;
    height: 20px;
    background: url('../icons/phone--black.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.portal-phone:hover {
    text-decoration: none;
    color: var(--blue-100);
    background: var(--blue-5);
    border: 1px solid var(--blue-40);
}

.portal-phone:hover:before {
    background: url('../icons/phone--blue.svg') no-repeat;
    background-size: contain;
}

.order-portal-welcome {
    padding-left: 40px;
}

.order-portal-welcome:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
    background: url('../icons/hello.svg') no-repeat;
    background-size: contain;
}

.waiting-order {
    height: 60px;
    border: 1px solid var(--black-15);
    border-radius: 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0 10px 0 20px;
}

.waiting-order span {
    padding-right: 14px;
    margin-right: 14px;
    border-right: 1px solid var(--black-5);
}

.waiting-orders-container p {
    margin-bottom: 10px;
}

.waiting-order .no-border, .waiting-order.disabled .no-border {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}

.provider-name {
    padding-left: 25px;
    position: relative;
}

.provider-name:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    background: url('../icons/order--blue.svg') no-repeat;
    background-size: contain;
}

.waiting-orders-container {
    font-weight: 500;
    margin-bottom: 30px;
}

.order-receipt-confirm {
    height: 40px;
    border: 1px solid var(--green-170-40);
    background: var(--green-30);
    border-radius: 12px;
    padding: 0 15px;
    color: var(--green-170);
    font-weight: 600;
    transition: all .3s;
}

.order-receipt-confirm:hover {
    background: var(--green-100);
    color: var(--white-100);
    border: 1px solid var(--green-100);
}

.waiting-order.disabled {
    width: 100%;
}

.waiting-orders-block {
    width: fit-content;
    justify-content: flex-start;
}

.waiting-orders-block .waiting-order.disabled {
    justify-content: flex-start;
    background: var(--black-5);
}

.waiting-orders-block .waiting-order.disabled span {
    color: var(--black-60);
}

.waiting-order.disabled .provider-name:before {
    background: url('../icons/order--gray.svg') no-repeat;
    background-size: contain;
}

.waiting-order.disabled span {
    border-right: 1px solid var(--black-15);
}

.waiting-order-warning {
    background: var(--blue-5);
    color: var(--blue-100);
    height: 42px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 12px;
    font-size: .9em;
}

.delivery-date-container {
    padding: 14px;
    border: 1px solid var(--black-15);
    border-radius: 18px;
    margin-bottom: 30px;
    font-weight: 500;
}

.delivery-date-title {
    padding-left: 35px;
    position: relative;
}

.delivery-date-title:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    background: url('../icons/shipping--blue.svg') no-repeat;
    background-size: contain;
}

.warning-delivery {
    color: var(--red-100);
}

.delivery-container {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datepicker-block {
    width: 200px;
    height: 100%;
}

.datepicker-block .estimation-button {
    height: 100%;
    width: calc(100% - 5px);
    background: var(--blue-100);
    border: 0;
    border-radius: 12px 2px 2px 12px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.datepicker-block .estimation-button:hover {
    background: var(--blue-120);
}

.datepicker-block .estimation-button span {
    font-weight: 600;
    font-size: 1.1em;
}

.datepicker-block .estimation-button img {
    margin-bottom: 10px;
}

.address-block {
    width: calc(100% - 200px);
    height: 100%;
    background: var(--blue-5);
    border-radius: 2px 12px 12px 2px;
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 25px;
}

.address-block span {
    margin-bottom: 2px;
}

.address-block .delivery {
    font-weight: 600;
    color: var(--blue-120);
}

.address-block .provider-title {
    font-weight: 700;
    font-size: 1.3em;
}

.address-block .provider-address {
    font-weight: 600;
}

.empty-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    padding: 0 20px;
}

.empty-list-container span {
    font-weight: 500;
    margin: 30px 0;
    text-align: center;
}

.multiple-tag-list {
    display: flex;
}

.multiple-tag-list span {
    margin-right: 10px;
}

.empty-text {
    color: var(--black-30);
    font-style: italic;
}

#root .react-datepicker__time {
    border-radius: 12px;
}

.classic-select {
    height: 34px;
    padding: 0 10px;
    border-radius: 10px;
    border: 1px solid var(--black-15);
    font-size: .9em;
    font-weight: 600;
    color: var(--blue-120);
}

.input-orders-container .list-container {
    margin: 30px 0;
    height: unset;
    border-radius: 12px;
    border: 1px solid var(--black-5);
}

.list-container tr {
    height: 40px;
}

.input-orders-container .list-container tbody tr td:first-child {
    padding-left: 30px;
}

.input-orders-container .list-container thead th:first-child {
    padding-left: 54px;
}

.input-orders-container .list-container .column-icon:first-child:before {
    left: 30px;
}

.season-chooser-container .single-search-input .select__value-container {
    padding-left: 6px;
    margin-right: 6px;
}

.season-chooser-container .select__dropdown-indicator {
    padding: 8px 4px;
}

.user-options a {
    text-decoration: none;
}

.MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: var(--blue-100);
}

.MuiCircularProgress-root.MuiCircularProgress-indeterminate.white-loader {
    color: var(--white-100);
}

.menu-add-first, .drop-group-block {
    font-size: 0.8em;
    background: var(--black-5);
    padding: 10px;
    width: calc(100% - 8px);
    display: block;
    font-weight: 600;
    border-radius: 8px;
    color: var(--black-60);
    border: 2px dashed var(--black-10);
    margin: auto;
    transition: all .3s;
}

.drop-group-block {
    width: 100%;
    border: 2px dashed var(--green-100);
    color: var(--green-100);
    font-size: 0.9em;
    margin-top: 10px;
}

.menu-add-first:hover {
    border: 2px dashed var(--green-100);
    color: var(--green-100);
}

.MuiButtonBase-root.MuiTab-root {
    text-transform: none;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    min-height: 60px;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: var(--blue-120);
}

.MuiTabs-root .MuiTabs-indicator {
    background-color: var(--blue-100);
}

.accordion-container {
    border: 1px solid var(--black-5);
    border-radius: 8px;
    margin-bottom: 20px;
}

.accordion-container .MuiPaper-root.MuiPaper-elevation:first-child {
    border-radius: 12px 12px 0 0;
}

.accordion-container .MuiPaper-root.MuiPaper-elevation:last-child {
    border-radius: 0 0 12px 12px;
}

.MuiAccordionSummary-content .view-subtitle, .training-edit-block .view-subtitle {
    margin-bottom: 0;
}

.accordion-container .subscription-validation .view-subtitle h2,
.training-edit-block .view-subtitle h2,
.change-subscription-container .view-subtitle h2 {
    font-weight: 600 !important;
    font-size: .9em;
    padding-left: 24px;
}

.accordion-container .subscription-validation .view-subtitle:before {
    width: 14px;
    height: 14px;
}

.MuiPaper-root.MuiAccordion-root:before {
    display: none;
}

.MuiPaper-root.MuiPaper-rounded.MuiAccordion-root:first-child {
    border-top: 0;
}

.MuiPaper-root.MuiPaper-rounded.MuiAccordion-root {
    border-top: 1px solid var(--black-5);
}

.MuiPaper-root.MuiPaper-rounded.MuiAccordion-root *,
.MuiFormGroup-root .MuiTypography-root,
.MuiStepper-root .MuiStep-root *,
.MuiPaper-root.MuiPaper-elevation * {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
}

.view-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.empty-list-container.out-list {
    font-size: .9em;
}

.subscription-trainings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 20px;
}

.accordion-training-hours {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.accordion-training-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

#options-tooltip .MuiTooltip-tooltip {
    background: white;
    color: var(--black-100);
    padding: 4px;
    border: 1px solid var(--black-5);
    font-size: 0.9em;
    border-radius: 10px;
    margin: 8px 0;
}

.quill.padding {
    padding: 10px;
}

#options-tooltip .tooltip-container {
    display: flex;
    flex-direction: column;
}

#options-tooltip .tooltip-container span, #options-tooltip .tooltip-container a {
    font-weight: 700;
    cursor: pointer;
    padding: 6px 10px 6px 30px;
    border-radius: 6px;
    position: relative;
    transition: all .2s;
    display: block;
    color: var(--blue-120);
    font-size: .9em;
}

#options-tooltip .tooltip-container span:hover, #options-tooltip .tooltip-container a:hover {
    background: var(--blue-5);
    color: var(--blue-120);
}

#options-tooltip .tooltip-container span:before, #options-tooltip .tooltip-container a:before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(0, -50%);
    background: url('../icons/small-add--blue.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .add {
    color: var(--green-170);
}

#options-tooltip .tooltip-container .add:hover {
    background: var(--green-30);
    color: var(--green-170);
}

#options-tooltip .tooltip-container .view:before {
    background: url('../icons/eye--blue.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .color:before {
    background: url('../icons/palette--blue.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .edit:before {
    background: url('../icons/pencil--blue.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .change-group:before {
    background: url('../icons/refresh--blue.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .home:before {
    background: url('../icons/home-location--blue.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .sort:before {
    background: url('../icons/sort--blue.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .add:before {
    background: url('../icons/small-add--green.svg') no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    left: 4px;
}

.user-info-container {
    border-left: 1px solid var(--black-5);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.user-info-block {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black-5);
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s;
}

.public-panel-container .user-info-block {
    padding: 6px;
}

.user-img-container {
    width: 30px;
    height: 30px;
    background: var(--black-5);
    border-radius: 17px;
    margin-right: 10px;
    overflow: hidden;
}

.user-img-container img {
    width: 30px;
    height: 30px;
}

.user-info-block:hover {
    background: var(--blue-5);
    border-color: var(--blue-40);
}

#options-tooltip .tooltip-container .delete, #options-tooltip .tooltip-container .logout {
    color: var(--red-170);
}

#options-tooltip .tooltip-container .delete:hover, #options-tooltip .tooltip-container .logout:hover {
    background: var(--red-30);
    color: var(--red-170);
}

#options-tooltip .tooltip-container .logout:before {
    background: url('../icons/logout--red.svg') no-repeat;
    background-size: contain;
}

#options-tooltip .tooltip-container .delete:before {
    background: url('../icons/bin--red.svg') no-repeat;
    background-size: contain;
}

.entity-add-container {
    border: 1px solid var(--black-5);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.entity-add-container .training-edit-block:last-child {
    padding-top: 5px;
}

.customer-panel-content .entity-add-container {
    border-radius: 12px;
}

.training-edit-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--black-5);
}

.training-edit-block:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.add-training-button {
    border: 1px dashed var(--black-15);
    background: var(--black-5);
    border-radius: 6px;
    width: 100%;
    font-size: .9em;
    font-weight: 600;
    color: var(--black-70);
    transition: all .3s;
    height: 34px;
}

.add-training-button:hover {
    color: var(--blue-120);
    background: var(--blue-5);
    border: 1px solid var(--blue-60);
}

.MuiButtonBase-root.MuiButton-root {
    padding: 0 8px;
    font-family: 'Manrope', sans-serif;
    text-transform: none;
    border-radius: 6px;
}

.custom-list-container {
    border: 1px solid var(--black-5);
    border-radius: 8px;
    overflow: hidden;
}

.list-container.non-selectable thead th:first-child {
    padding-left: 40px;
}

.list-container.non-selectable .column-icon:first-child:before {
    left: 16px;
}

.list-container.non-selectable tbody tr td:first-child {
    padding-left: 16px;
}

.tabs-container .list-container.non-selectable tbody tr td:first-child {
    padding-left: 20px;
}

.tabs-container .list-container.non-selectable thead th:first-child {
    padding-left: 44px;
}

.MuiPaper-root.MuiDialog-paper {
    border-radius: 8px;
}

.group-chooser-block {
    margin-bottom: 4px;
}

.group-name-container {
    display: flex;
    align-items: center;
}

.group-chooser-block .checkbox-container {
    margin: unset;
}

.group-chooser-block .group-name {
    font-weight: 500;
}

.category-block {
    border: 1px solid var(--black-5);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.category-block.is-over {
    border: 2px dashed var(--green-100);
    background: var(--green-30);
}

.category-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-groups-container {
    margin-top: 10px;
}

.category-group-block {
    border: 1px solid var(--black-5);
    padding: 5px 10px 5px 32px;
    border-radius: 8px;
    background: var(--white-100);
    box-shadow: 0 0 4px var(--black-5);
    margin-bottom: 4px;
    font-size: .9em;
    font-weight: 600;
    position: relative;
    cursor: move;
    transition: all .2s;
}

.category-group-block:hover {
    background: var(--black-5);
    border: 1px solid var(--black-15);
}

.category-group-block:before {
    content:'';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    width: 12px;
    height: 12px;
    background: url('../icons/grip-dots--gray.svg') no-repeat;
    background-size: contain;
}

.small-empty-message {
    font-weight: 500;
    font-size: .9em;
    padding-left: 26px;
    position: relative;
    color: var(--black-70);
}

.small-empty-message:before {
    content:'';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 16px;
    height: 16px;
    background: url('../icons/info--blue.svg') no-repeat;
    background-size: contain;
}

.sorted-card .menu-opening-item:before {
    background: url('../icons/grip-dots--blue.svg') no-repeat;
    background-size: contain;
}

.sorted-card .menu-opening-item, .sorted-card .menu-opening-item span {
    cursor: move;
}

.sorted-card .menu-opening-item:hover {
    background: var(--white-100);
}

.small-note {
    font-size: .9em;
}

.very-small-note {
    font-size: .9em;
    font-style: italic;
    font-weight: 500;
    color: var(--black-70);
}

.trainings-mandatory-chooser {
    margin-bottom: 15px;
}

.full-group-places {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    margin-bottom: 20px;
    width: fit-content;
}

.rich-text-container .quill .ql-toolbar {
    border-radius: 12px 12px 0 0;
}

.rich-text-container .quill .ql-container {
    border-radius: 0 0 12px 12px;
}

.rich-text-container.toolbar-hidden .quill .ql-container {
    border-radius: 0;
}

.rich-text-container.toolbar-hidden .ql-editor {
    padding: 0;
}

.rich-text-container .ql-editor strong {
    font-weight: bolder;
}

.rich-text-container.toolbar-hidden .ql-editor.ql-blank {
    display: none;
}

.entity-with-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.entity-list-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.entity-list-title span:first-child {
    font-weight: 600;
}

.entity-info {
    font-size: .9em;
}

.entity-info.green {
    color: var(--green-170);
}

.entity-info.red {
    color: var(--red-170);
}

#options-tooltip {
    z-index: 999999;
}

.MuiButtonBase-root.MuiTab-root.notification-tab {
    padding-right: 45px;
    position: relative;
}

.MuiButtonBase-root.MuiTab-root.notification-tab .tab-badge {
    background: var(--black-5);
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.8em;
}

.MuiButtonBase-root.MuiTab-root.notification-tab .tab-badge.error {
    background: var(--red-100);
    color: var(--white-100);
}

.MuiButtonBase-root.MuiTab-root.notification-tab .tab-badge.success {
    background: var(--green-100);
    color: var(--white-100);
}

.subscription-validation .validation-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.subscription-validation .validation-buttons .main-button {
    width: 100%;
}

.admin-group-view .empty-list-container {
    height: 200px;
    margin-bottom: 20px;
}

.admin-group-view .view-container.subscriptions .empty-list-container {
    border-radius: 12px;
    border: 1px solid var(--black-5);
}

.subscription-payment-recap {
    border: 1px solid var(--blue-30);
    background: var(--blue-5);
    padding: 10px;
    border-radius: 8px;
    font-size: .9em;
    transition: all 3s;
    min-height: 40px;
}

.subscription-payment-recap.loading {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 3s;
}

.subscription-payment-details span.title, .subscription-payment-details span.price {
    font-weight: 600 !important;
}

.subscription-payment-details span.title {
    color: var(--black-70);
}

.subscription-payment-details span.title.total {
    color: var(--blue-120);
}

.subscription-payment-details span.price.total {
    font-size: 1.1em;
    font-weight: 700 !important;
    color: var(--blue-120);
}

.subscription-payment-details span.price {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.discount-add-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-bottom: 1px solid var(--blue-20);
    border-top: 1px solid var(--blue-20);
    padding: 10px 0;
}

.discount-line {
    display: flex;
    align-items: center;
}

.MuiDialog-root.MuiModal-root {
    z-index: 999999;
}

.discount-line .MuiButtonBase-root.MuiIconButton-root {
    padding: 0;
}

.MuiButtonBase-root.MuiAccordionSummary-root .custom-tag {
    padding: 0 9px;
}

.groups-chooser.training-selector-container .training-selector-block {
    margin-bottom: 0;
}

.group-add .error-message,
.group-edit .error-message,
.customer-profiles .error-message,
.error-message.column {
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 10px;
    margin-bottom: 20px;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
    background-color: var(--blue-100);
    border-radius: 8px;
}

.subscription-accepted-container {
    border: 1px solid var(--black-5);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.subscription-accepted-container:last-child {
    margin-bottom: 0;
}

.list-container {
    border: 1px solid var(--black-5);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}

.accordion-container .list-container {
    margin-bottom: 0;
}

.subscription-view .view-header, .view-header.subscriptions, .account-view .view-header {
    padding-right: 15px;
}

.view-subtitle .custom-tag {
    font-size: 0.8em;
    font-weight: 700 !important;
}

.group-update-container {
    border: 1px solid var(--black-5);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.group-update-container .single-search-input {
    margin-bottom: 20px;
}

.group-update-container .training-selector-block:last-child {
    margin-bottom: 0;
}

.group-update-container .subscription-trainings {
    margin-bottom: 0;
}

.group-update-container div:last-child {
    margin-bottom: 0;
}

.group-update-icon.MuiSvgIcon-root {
    width: 100%;
    margin-bottom: 15px;
}

.main-search-bar .select__indicators {
    display: none;
}

.customers-list .view-container {
    padding: 0;
}

.customers-list .list-container {
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
}

.profile-view-button {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--white-100);
    border: 1px solid var(--black-15);
    cursor: pointer;
    transition: all .3s;
}

.profile-view-button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    transform: translate(-50%, -50%);
    background: url('../icons/eye--blue.svg') no-repeat;
    background-size: contain;
}

.profile-view-button:hover {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
    color: var(--white-100);
}

.profile-view-button:hover:after {
    background: url('../icons/eye--white.svg') no-repeat;
    background-size: contain;
}

.profile-subscription-line {
    border: 1px solid var(--black-5);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-subscription-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.profile-subscription-title span:first-child {
    font-size: .9em;
    font-weight: 600;
}

.subscription-profile-recap {
    font-size: .9em;
    border: 1px solid var(--black-5);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    position: relative;
}

.subscription-profile-recap.trainings .subscription-profile-recap-lines span:not(.custom-tag), .subscription-profile-recap.trainings .subscription-profile-recap-lines a {
    margin-bottom: 10px;
}

.subscription-profile-recap-lines span:not(.custom-tag), .subscription-profile-recap-lines a {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    position: relative;
    margin-bottom: 4px;
    width: fit-content;
}

.subscription-profile-recap-lines.subscription span:not(.custom-tag), .subscription-profile-recap-lines.subscription a {
    margin-bottom: 10px;
}

.subscription-profile-recap-lines span:not(.custom-tag):last-child, .subscription-profile-recap-lines a:last-child {
    margin-bottom: 0 !important;
}

.subscription-profile-recap-lines a {
    color: var(--blue-100);
}

.subscription-profile-recap-lines span:not(.custom-tag):before, .subscription-profile-recap-lines a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background: url('../icons/info--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines span.user:before {
    background: url('../icons/round-user--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines span.user {
    font-weight: 700;
}

.subscription-profile-recap-lines span.birth:before {
    background: url('../icons/birth-cake--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines span.id:before {
    background: url('../icons/id--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines span.address:before {
    background: url('../icons/marker-rounded--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines span.calendar:before {
    background: url('../icons/calendar-day--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines span.status:before {
    background: url('../icons/status--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines span.date:before {
    background: url('../icons/calendar-day--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines a.email:before {
    background: url('../icons/email-blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-lines a.phone:before {
    background: url('../icons/phone--blue.svg') no-repeat;
    background-size: contain;
}

.subscription-profile-recap-buttons {
    position: relative;
}

.subscription-profile-recap .view-template-button {
    top: 15px;
    right: 15px;
}

.customer-profile-subscriptions {
    margin-top: 15px;
}

.customer-profile-subscriptions .subscription-profile-recap {
    font-size: 1em;
}

.customer-profile-subscriptions div:last-child {
    margin-bottom: 0;
}

.right-top-buttons {
    position: absolute;
    top: 15px;
    right: 15px;
}

.view-subtitle.margin-bottom, .subscription-line.margin-bottom {
    margin-bottom: 25px;
}

.form-info-container .profile-subscription-line .rich-text-container {
   margin-bottom: 0;
}

.single-search-input.public, .single-search-input.public .select__control {
    height: 100%;
    max-height: 54px;
}

.single-search-input.public .select__control {
    border-color: var(--black-30);
}

.single-search-input.public .select__placeholder {
    display: flex;
    align-items: center;
}
.single-search-input.error .select__control {
    border-color: var(--red-120);
}

.single-search-input.error .select__placeholder {
    color: var(--red-120);
}

.single-search-input.public .select__value-container, .single-search-input.public .select__value-container * {
    height: 100%;
}

.planning-view .view-container {
    height: calc(100vh - 120px);
    font-size: .9em;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 15px;
}

.fc .fc-col-header-cell-cushion {
    color: var(--blue-100);
}

.fc-v-event {
    background-color: var(--blue-100);
}

.fc .fc-scrollgrid {
    border-radius: 8px;
}

.fc .fc-scrollgrid-section-header > * {
    border-radius: 8px 8px 0 0;
}

.fc-timegrid-event, .fc-timegrid-more-link {
    border-radius: 4px;
}

.fc .fc-scrollgrid-section-liquid > td {
    border-radius: 0 0 8px 8px;
}

.fc .fc-button {
    border-radius: 8px;
    font-weight: 600;
}

.fc .fc-button-primary:disabled {
    background-color: var(--white-100);
    color: var(--blue-60);
    border-color: var(--black-15);
    opacity: 1;
}

.fc .fc-button-primary:disabled:hover {
    background-color: var(--white-100);
    color: var(--blue-60);
}

.fc .fc-button-primary:hover {
    background-color: var(--blue-5);
    color: var(--blue-100);
    border-color: var(--black-15);
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:focus {
    box-shadow: unset;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    background-color: var(--blue-5);
    border-color: var(--black-15);
    color: var(--blue-100);
}

.fc .fc-button-primary {
    background-color: var(--white-100);
    border-color: var(--black-15);
    color: var(--blue-100);
}

.fc .fc-toolbar-title {
    font-size: 1.4em;
    font-weight: 600;
}

.planning-view a {
    color: var(--blue-100);
}

.planning-filter {
    padding: 11px;
}

.planning-filter-row {
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
}

.planning-filter-row:last-child {
    margin-bottom: 0;
}

.planning-filter-select {
    font-size: 0.9em;
    color: var(--blue-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--black-5);
}

.planning-filter-select span {
    padding: 0 6px;
    border-radius: 4px;
}

.planning-filter-select span:hover {
    background: var(--blue-5);
}

.planning-filter-button:before {
    display: none;
}

.planning-filter-button {
    padding: 0 12px;
}

.group-color-picker {
    width: 14px;
    height: 14px;
    border-radius: 10px;
    background: var(--blue-100);
    cursor: pointer;
    position: relative;
}

.color-container {
    padding: 11px;
}

.group-color-picker:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid var(--black-15)
}

.group-icon {
    min-width: 16px;
}

.view-title.group h1 {
    padding-left: 5px;
}

.view-title.group h1:before {
    display: none;
}

.options-item {
    display: flex;
    margin-bottom: 8px;
    cursor: move;
}

.sorted-card:last-child .options-item {
    margin-bottom: 0;
}

.options-item .custom-tag {
    margin-left: 12px;
    margin-right: 6px;
}

.options-sorting-container {
    border: 1px solid var(--black-15);
    border-radius: 12px;
    padding: 10px 15px;
}

.full-height {
    height: 100%;
}

.view-subtitle.field {
    justify-content: flex-start;
    gap: 4px;
}

.no-option-message {
    text-align: center;
    color: hsl(0, 0%, 60%);
    padding: 8px 12px;
    box-sizing: border-box;
}

.subscription-container {
    height: calc(100vh - 60px);
    overflow: scroll;
    padding-bottom: 40px;
}

.subscription-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 6px;
    padding: 40px 0;
}

.subscription-instructions h1 {
    font-size: 1.4em;
    font-weight: 700;
}

.subscription-instructions h2 {
    font-size: 1.2em;
    font-weight: 600;
}

.subscription-container .MuiTypography-root {
    font-family: 'Manrope', sans-serif;
    font-size: 1em;
    font-weight: 600;
}

.subscription-container .divider {
    width: 100%;
    height: 4px;
    background: var(--black-15);
}

.signature-container {
    position: relative;
    height: 100%;
}

.sigCanvas {
    border: 1px solid #c6c6c6;
    border-radius: 12px;
    width: 100%;
    min-height: 150px;
    height: 100%;
}

.erase-signature {
    position: absolute;
    top: 8px;
    right: 6px;
}

.erase-signature button {
    border-radius: 7px;
}

.error-signature {
    position: absolute;
    left: 6px;
    top: 7px;
}

.subscription-container .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: var(--white-100);
}

.subscription-container .error-message {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.MuiFormControlLabel-root.errored {
    color: var(--red-100);
}

.subscription-instructions img {
    padding-bottom: 20px;
}

.validation-container {
    padding: 20px;
    height: calc(100vh - 60px);
    overflow: scroll;
}

.list-container .main-button {
    height: 28px;
    border-radius: 10px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.rgpd-container {
    background: var(--black-15);
    font-size: .9em;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 100%;
    justify-content: center;
}

.done-in {
    display: flex;
    align-items: center;
    gap: 10px;
}

.done-in .MuiFormControl-root.MuiTextField-root {
    width: auto;
}

@media (max-width: 981px) {
    .custom-modal-container {
        margin: 0 20px;
    }
    .custom-modal-content {
        max-height: 70vh;
    }
    .accordion-training-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .accordion-training-container .pool-name {
        width: 100%;
    }
}